import axios from "axios"
import { FormData, exists } from "browser-monads"
import { apiEndpoint } from "./_config"

const InvoiceService = {
  lookupInvoice: token => {
    if (!exists(FormData)) {
      return
    }
    const formData = new FormData()
    formData.append("action", "10a_invoice_lookup")
    formData.append("token", token)
    return axios.post(apiEndpoint, formData).then(response => {
      if (response && response.data && response.data.invoice) {
        return response.data.invoice
      }
      return null
    })
  },
}
export default InvoiceService
