import axios from "axios"
import { FormData, exists } from "browser-monads"
import { apiEndpoint } from "./_config"

const PaymentService = {
  createPaymentIntent: async (invoiceToken, payment, receipt) => {
    if (!exists(FormData)) {
      return
    }
    const formData = new FormData()
    formData.append("action", "10a_create_payment_intent")
    formData.append("reference_id", invoiceToken)
    formData.append(
      "payment_amount",
      parseFloat(`${payment.amount}`.replace(/[^\d.]/g, "") || 0)
    )
    formData.append("payment_currency", payment.currencyCode)
    formData.append("receipt_email", receipt.email)

    // send request for payment intent to the WP REST API
    const piResponse = await axios.post(apiEndpoint, formData)
    if (!piResponse || !piResponse.data || !piResponse.data.client_secret) {
      throw new Error("Error submitting payment")
    }
    return piResponse.data.client_secret
  },
}
export default PaymentService
