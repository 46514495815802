import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import FormFieldInputNumber from "@src/components/form-field-input-number"
import { getCurrency } from "@src/utils/currency"

const StyledCurrencyCode = styled.div``
const StyledCurrencySymbol = styled.div`
  position: absolute;
  left: 6px;
  top: 0;
  color: ${props => props.theme.lightGray};
  input:focus ~ & {
    color: ${props => props.theme.darkGray};
  }
  transition: color 0.2s ease-out;

  font-size: 14px;
  left: 6px;
  line-height: 34px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 18px;
    left: 6px;
    line-height: 42px;
  }
`
const StyledCurrencyField = styled(FormFieldInputNumber)`
  & input {
    padding-left: 20px;
    text-align: right;
  }

  & .field-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > input {
      flex: 0 0 100px;
    }
    & > ${StyledCurrencyCode} {
      flex: 0 0 auto;
      margin-left: 6px;
    }
    & > .field-error {
      flex: 0 0 100%;
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & input {
      padding-left: 24px;
    }
    & .field-content {
      & > input {
        flex: 0 0 125px;
      }
    }
  }
`

const FormFieldCurrency = ({
  currency: currencyCode,
  decimals: showDecimals,
  ...props
}) => {
  const currency = useRef(null)

  useEffect(() => {
    currency.current = getCurrency(currencyCode)
  }, [currencyCode])

  const formatCurrency = (value, decimals, separator) => {
    if (currency.current && currency.current.format) {
      return currency.current.format(value, {
        showDecimals: !!decimals,
        separator,
      })
    }
    return value
  }

  return (
    <StyledCurrencyField
      formatNumber={formatCurrency}
      decimals={(showDecimals && currency.current?.decimals) || undefined}
      {...props}
    >
      {currency.current && currency.current.symbol && (
        <StyledCurrencySymbol>{currency.current.symbol}</StyledCurrencySymbol>
      )}
      <StyledCurrencyCode>{currencyCode}</StyledCurrencyCode>
    </StyledCurrencyField>
  )
}
FormFieldCurrency.propTypes = {
  currency: PropTypes.string,
  decimals: PropTypes.bool,
}
FormFieldCurrency.defaultProps = {
  decimals: true,
}
export default FormFieldCurrency
