import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { LayerContext } from "@src/context/layer-context"
import Spinner from "@src/components/core-spinner"
const StyledSpinner = styled(Spinner)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${props => props.theme.layers.overlay2 + 1};
`
const SpinnerOverlay = ({ overlayId, ...props }) => {
  const Layers = useContext(LayerContext)
  const layerId = overlayId || "spinner"
  useEffect(() => {
    Layers.open({
      layerId,
      level: 2,
      light: true,
      lock: true,
    })
    return () => {
      Layers.close(layerId)
    }
  }, [layerId, Layers])
  return <StyledSpinner {...props} />
}
SpinnerOverlay.propTypes = {
  overlayId: PropTypes.string,
}
export default SpinnerOverlay
