import React, { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Elements as StripeElements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import InvoiceService from "@src/services/payments/invoices"
import GTM from "@src/services/gtm"
import ErrorPage404 from "@src/pages/404"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import Spinner from "@src/components/core-spinner"
import FormatDate from "@src/components/core-value-date"
import PaymentForm from "@src/components/form-payment"
import { ImagePropTypes } from "@src/components/core-image"
import { getCurrency } from "@src/utils/currency"
import { SITE_DOMAIN } from "@src/utils/constants"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK)
const stripeOptions = {}

const StyledLoadingSection = styled(ResponsiveSection)`
  text-align: center;
`
const StyledDetails = styled.dl`
  float: right;
  clear: both;
  display: table;
  & > div {
    display: table-row;
    & > dt,
    & > dd {
      display: table-cell;
      padding: 6px 10px;
    }
    & > dt {
      font-weight: bold;
    }
  }
`
const StyledInvoiceTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  & th,
  & td {
    padding: 6px 10px;
  }
  & th.description,
  & td.description {
    text-align: left;
  }
  & th.quantity,
  & td.quantity {
    text-align: center;
  }
  & th.currency,
  & td.currency,
  & td.total-label {
    text-align: right;
  }
  & td.currency-code {
    font-weight: bold;
    font-size: 0.8em;
  }

  & thead > tr,
  & tbody > tr {
    border-bottom: 1px solid ${props => props.theme.lighterGray};
  }
  & tfoot > tr.amount-due,
  & tfoot > tr.payments-total {
    border-top: 1px solid ${props => props.theme.lighterGray};
  }

  & tfoot > tr.invoice-total,
  & tfoot > tr.invoice-balance,
  & tfoot > tr.amount-due {
    font-weight: bold;
  }
`
const currencyOpts = {
  showSymbol: true,
  showDecimals: true,
}

const InvoicePaymentPage = ({ data, params }) => {
  const {
    tenAdventures: {
      page: { title, uri: pageUri, seo: pageSeo },
      termsPage,
    },
  } = data

  const [loaded, setLoaded] = useState(false)
  const [invoice, setInvoice] = useState(null)

  useEffect(() => {
    if (!params["*"] || params["*"].indexOf("/") !== -1) {
      setLoaded(404)
      return
    }
    const invoiceToken = params["*"]
    InvoiceService.lookupInvoice(invoiceToken)
      .then(invoice => {
        if (invoice) {
          GTM.dataLayerPushPageInfo({
            template: "payment-invoice",
          })
          setInvoice(invoice)
          setLoaded(true)
        } else {
          setInvoice(null)
          setLoaded(404)
        }
      })
      .catch(() => {
        setLoaded(404)
      })
  }, [params, setLoaded, setInvoice])

  const formatCurrency = useCallback(
    amount => {
      const currency = getCurrency(invoice.details.currency)
      if (currency) {
        return currency.format(amount, currencyOpts)
      }
      return amount
    },
    [invoice]
  )

  const handlePaymentReceived = useCallback(
    values => {
      if (!invoice || !invoice.details || !invoice.totals) {
        return
      }
      const dataLayer = {
        event: "10a.payment.success",
        "invoice-currency": invoice.details.currency,
        "invoice-balance-before": invoice.totals.balance,
        "payment-amount": values.payment_amount,
        "invoice-balance-after": invoice.totals.balance - values.payment_amount,
      }
      GTM.dataLayerPush(dataLayer)
    },
    [invoice]
  )

  return loaded === 404 || (loaded === true && !invoice) ? (
    <ErrorPage404 />
  ) : (
    <PrimaryLayout variant="gray">
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, pageSeo.metaRobotsNoindex],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />

      {!loaded ? (
        <StyledLoadingSection contained>
          <h2>Loading invoice...</h2>
          <Spinner align="center" />
        </StyledLoadingSection>
      ) : (
        <>
          <ResponsiveSection align="left" contained>
            <h2>{title}</h2>
            <StyledDetails>
              <div>
                <dt>Invoice #</dt>
                <dd>{invoice.details.number}</dd>
              </div>
              <div>
                <dt>Invoice date</dt>
                <dd>
                  <FormatDate date={invoice.details.date || null} />
                </dd>
              </div>
              <div>
                <dt>Due date</dt>
                <dd>
                  <FormatDate date={invoice.payment_due.due_date || null} />
                </dd>
              </div>
            </StyledDetails>
          </ResponsiveSection>
          <ResponsiveSection align="left" contained>
            <StyledInvoiceTable>
              <thead>
                <tr>
                  <th className="description">Description</th>
                  <th className="quantity">Quantity</th>
                  <th className="currency">Price</th>
                  <th className="currency">Amount</th>
                  <th className="currency-code"></th>
                </tr>
              </thead>
              <tbody>
                {invoice.items.map((item, index) => (
                  <tr key={`${invoice.details.number}-${item}-${index}`}>
                    <td className="description">{item.description}</td>
                    <td className="quantity">{item.quantity}</td>
                    <td className="currency">{formatCurrency(item.value)}</td>
                    <td className="currency">{formatCurrency(item.total)}</td>
                    <td className="currency-code"></td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" className="total-label">
                    Subtotal
                  </td>
                  <td className="currency">
                    {formatCurrency(invoice.totals.subtotal)}
                  </td>
                  <td className="currency-code"></td>
                </tr>
                <tr>
                  <td colSpan="3" className="total-label">
                    Total Taxes
                  </td>
                  <td className="currency">
                    {formatCurrency(invoice.totals.taxes)}
                  </td>
                  <td className="currency-code"></td>
                </tr>
                <tr className="invoice-total">
                  <td colSpan="3" className="total-label">
                    Invoice Total
                  </td>
                  <td className="currency">
                    {formatCurrency(invoice.totals.total)}
                  </td>
                  <td className="currency-code">{invoice.details.currency}</td>
                </tr>
                {invoice.totals.payments ? (
                  <>
                    <tr className="payments-total">
                      <td colSpan="3" className="total-label">
                        Total Payments Received
                      </td>
                      <td className="currency">
                        {formatCurrency(invoice.totals.payments)}
                      </td>
                      <td className="currency-code">
                        {invoice.details.currency}
                      </td>
                    </tr>
                    {invoice.totals.refunds ? (
                      <tr className="refunds-total">
                        <td colSpan="3" className="total-label">
                          Total Refunds Issued
                        </td>
                        <td className="currency">
                          {formatCurrency(invoice.totals.refunds)}
                        </td>
                        <td className="currency-code">
                          {invoice.details.currency}
                        </td>
                      </tr>
                    ) : null}
                    <tr className="invoice-balance">
                      <td colSpan="3" className="total-label">
                        Invoice Balance
                      </td>
                      <td className="currency">
                        {formatCurrency(invoice.totals.balance)}
                      </td>
                      <td className="currency-code">
                        {invoice.details.currency}
                      </td>
                    </tr>
                  </>
                ) : null}
                <tr className="amount-due">
                  <td colSpan="3" className="total-label">
                    Amount Due Today
                  </td>
                  <td className="currency">
                    {formatCurrency(invoice.payment_due.minimum_due)}
                  </td>
                  <td className="currency-code">{invoice.details.currency}</td>
                </tr>
              </tfoot>
            </StyledInvoiceTable>
          </ResponsiveSection>
          <StripeElements stripe={stripePromise} options={stripeOptions}>
            <PaymentForm
              invoiceToken={invoice.details.token}
              currencyCode={invoice.details.currency}
              maximumAmount={invoice.totals.balance}
              defaultAmount={invoice.payment_due.minimum_due}
              paymentTerms={termsPage}
              onPaymentReceived={handlePaymentReceived}
            />
          </StripeElements>
          <ResponsiveSection className="transparent">
            <h2>Thank you for choosing 10Adventures!</h2>
          </ResponsiveSection>
        </>
      )}
    </PrimaryLayout>
  )
}
InvoicePaymentPage.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        content: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
      termsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
    }),
  }),
  params: PropTypes.object,
}
export default InvoicePaymentPage
export const query = graphql`
  query($id: ID!, $termsId: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        content
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
      termsPage: page(id: $termsId, idType: DATABASE_ID) {
        title
        content
      }
    }
  }
`
