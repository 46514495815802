import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import PaymentService from "@src/services/payments/payments"
import theme from "@src/styles/theme"
import ModalContainer from "@src/components/container-modal"
import ResponsiveSection from "@src/components/container-responsive-section"
import Form, {
  useFormContext,
  FormGroup,
  FormActions,
} from "@src/components/form"
import {
  StyledLabel,
  StyledRequired,
  ErrorMessage,
} from "@src/components/input-wrapper"
import FormFieldCurrency from "@src/components/form-field-currency"
import FormFieldInputCheckbox from "@src/components/form-field-input-checkbox"
import FormFieldEmailAddress from "@src/components/form-field-email"
import FormFieldInputPhoneNumber from "@src/components/form-field-input-phone-number"
import FormFieldInputText from "@src/components/form-field-input-text"
import Button from "@src/components/core-button"
import SpinnerOverlay from "@src/components/core-spinner-overlay"
import { getCurrency } from "@src/utils/currency"
const StyledTermsLink = styled(Button)`
  margin-left: 10px;
`
const StyledFormActions = styled(FormActions)`
  align-items: flex-start;
  & > button {
    margin-bottom: 0;
  }

  .form-success {
    font-size: 2rem;
    font-weight: 700;
  }
`
const StyledStripeCardElementWrapper = styled.div`
  max-width: 520px;
  margin-bottom: 18px;
  & .StripeElement {
    border: 1px solid ${props => props.theme.darkGray};
    border-radius: 4px;
    padding: 0 12px;
    &.StripeElement--focus {
      outline: ${props => props.theme.focusOutline};
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`
const TermsSection = styled(ResponsiveSection)`
  #payment-terms-modal {
    ul {
      padding-left: 20px;
    }
  }
`

// apply a forwardRef wrapper to Stripe's CardElement
const CardElementWithRef = forwardRef((props, ref) => {
  const wrapperRef = useRef()
  // register interactions for the ref
  // CardElement always has className of StripeElement
  // with additional classNames varying based on its state
  useImperativeHandle(ref, () => ({
    isEmpty: () => {
      const stripeElement = wrapperRef.current
        .getElementsByClassName("StripeElement")
        .item(0)
      return !!(
        stripeElement &&
        stripeElement.classList.contains("StripeElement--empty")
      )
    },
    isValid: () => {
      const stripeElement = wrapperRef.current
        .getElementsByClassName("StripeElement")
        .item(0)
      return !(
        stripeElement &&
        (stripeElement.classList.contains("StripeElement--empty") ||
          stripeElement.classList.contains("StripeElement--invalid"))
      )
    },
  }))
  // Stripe's CardElement doesn't allow ref, so use a div wrapper
  return (
    <div ref={wrapperRef}>
      <CardElement {...props} />
    </div>
  )
})
CardElementWithRef.displayName = "CardElement"
const PaymentForm = forwardRef(
  (
    {
      paymentTerms,
      currencyCode,
      defaultAmount,
      minimumAmount,
      maximumAmount,
      formStatus,
    },
    stripeCardElementRef
  ) => {
    const { setValue, trigger } = useFormContext()
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)
    const [stripeCardError, setStripeCardError] = useState(null)

    const toggleTermsAndConditions = useCallback(
      open => {
        if (open) {
          setIsTermsModalOpen(true)
        } else {
          setIsTermsModalOpen(false)
        }
      },
      [setIsTermsModalOpen]
    )
    const handleTermsAndConditionsAgreement = useCallback(
      accepted => {
        setValue("agree_terms_10adventures", accepted)
        trigger("agree_terms_10adventures")
        toggleTermsAndConditions(false)
      },
      [setValue, trigger, toggleTermsAndConditions]
    )

    const handleStripeCardChange = useCallback(
      event => {
        if (event.empty) {
          setStripeCardError("Please complete the payment card details.")
        } else if (event.error) {
          setStripeCardError(event.error.message)
        } else {
          setStripeCardError(null)
        }
      },
      [setStripeCardError]
    )

    const handleSubmitClick = useCallback(() => {
      if (
        stripeCardElementRef.current &&
        stripeCardElementRef.current.isEmpty()
      ) {
        setStripeCardError("Please complete the payment card details.")
      }
    }, [stripeCardElementRef, setStripeCardError])

    const formatCurrency = useCallback(
      (amount, currencyOpts) => {
        const currency = getCurrency(currencyCode)
        if (currency) {
          return currency.format(amount, currencyOpts)
        }
        return amount
      },
      [currencyCode]
    )

    return (
      <>
        <ResponsiveSection align="left" contained>
          <h2>
            Amount to pay
            <StyledRequired />
          </h2>
          <FormGroup>
            <FormFieldCurrency
              currency={currencyCode}
              id="payment-amount"
              name="payment_amount"
              value={defaultAmount}
              required
              validation={{
                validate: value => {
                  value =
                    (value && parseFloat(`${value}`.replace(/[^\d.]/g, ""))) ||
                    0
                  if (minimumAmount && value < minimumAmount) {
                    return `You must make a payment of at least ${formatCurrency(
                      minimumAmount
                    )}.`
                  } else if (maximumAmount && value > maximumAmount) {
                    return `You cannot pay more than ${formatCurrency(
                      maximumAmount
                    )}.`
                  }
                },
              }}
            />
          </FormGroup>
        </ResponsiveSection>
        <TermsSection align="left" contained>
          <h2>Terms &amp; Conditions</h2>
          <p>
            By selecting to complete this payment I acknowledge that I have read
            and accept the following:
          </p>
          <FormGroup>
            <FormFieldInputCheckbox
              id="agree-terms-10adventures"
              name="agree_terms_10adventures"
              variant="blue"
              required
              onChange={handleTermsAndConditionsAgreement}
              validation={{
                validate: value =>
                  value ||
                  "You must agree to the terms and conditions to complete the payment.",
              }}
            >
              <StyledTermsLink
                variant="plain"
                onClick={() => toggleTermsAndConditions(true)}
                title="Read the terms &amp; conditions for completion of the payment"
              >
                10Adventures Terms &amp; Conditions
              </StyledTermsLink>
            </FormFieldInputCheckbox>
          </FormGroup>
          <ModalContainer
            id="payment-terms-modal"
            isOpen={isTermsModalOpen}
            onClose={() => toggleTermsAndConditions(false)}
            title={paymentTerms && paymentTerms.title}
            content={paymentTerms && paymentTerms.content}
            variant="full-height"
            actions={
              <>
                <p>Do you agree to the 10Adventures Terms & Conditions?</p>
                <Button
                  icon="close"
                  size="small"
                  color="outline"
                  onClick={() => handleTermsAndConditionsAgreement(false)}
                >
                  I do not agree
                </Button>
                <Button
                  icon="check"
                  size="small"
                  onClick={() => handleTermsAndConditionsAgreement(true)}
                >
                  I agree
                </Button>
              </>
            }
          />
        </TermsSection>
        <ResponsiveSection align="left" contained>
          <h2>How would you like to pay?</h2>
          <StyledStripeCardElementWrapper>
            <StyledLabel>
              Payment card details
              <StyledRequired />
            </StyledLabel>
            <CardElementWithRef
              options={{
                style: {
                  base: {
                    color: theme.darkerGray,
                    fontSize: "18px",
                    lineHeight: "40px",
                  },
                },
              }}
              onChange={handleStripeCardChange}
              ref={stripeCardElementRef}
            />
            {stripeCardError && <ErrorMessage>{stripeCardError}</ErrorMessage>}
          </StyledStripeCardElementWrapper>
          <FormGroup>
            <FormFieldInputText
              id="customer-name"
              name="customer_name"
              label="Name on Card"
              required
              validation={{
                required: "This field is required.",
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormFieldInputPhoneNumber
              id="customer-phone"
              name="customer_phone"
              label="Contact Phone number"
              required
              validation={{
                required: "This field is required.",
              }}
            />
          </FormGroup>
        </ResponsiveSection>
        <ResponsiveSection align="left" contained>
          <h2>Where should we send your receipt?</h2>
          <FormGroup column>
            <FormFieldEmailAddress
              id="email"
              name="email"
              label="Email"
              placeholder="Email address"
              confirmation
              required
              validation={{
                required:
                  "Let us know where to contact you about your adventure.",
              }}
            />
          </FormGroup>
        </ResponsiveSection>
        <ResponsiveSection align="left" contained>
          <StyledFormActions>
            {(formStatus?.success && (
              <p className="form-success">{formStatus.success}</p>
            )) ||
              (formStatus?.error && (
                <p className="form-error">{formStatus.error}</p>
              )) ||
              (formStatus?.processing && (
                <p className="form-info">{formStatus.processing}</p>
              ))}
            <Button
              type="submit"
              icon="check"
              onClick={handleSubmitClick}
              disabled={
                formStatus && (formStatus.processing || formStatus.success)
                  ? true
                  : undefined
              }
            >
              {formStatus && formStatus.success
                ? "Payment Complete"
                : "Pay now"}
            </Button>
          </StyledFormActions>
        </ResponsiveSection>
      </>
    )
  }
)
PaymentForm.propTypes = {
  paymentTerms: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  currencyCode: PropTypes.string.isRequired,
  defaultAmount: PropTypes.number,
  minimumAmount: PropTypes.number,
  maximumAmount: PropTypes.number,
  formStatus: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
    processing: PropTypes.string,
  }),
}
PaymentForm.displayName = "PaymentForm"

const PaymentFormWrapper = ({
  invoiceToken,
  paymentTerms,
  currencyCode,
  defaultAmount,
  minimumAmount,
  maximumAmount,
  generateDataLayer,
  onPaymentReceived,
}) => {
  // reference: https://stripe.com/docs/stripe-js/react#useelements-hook
  const stripe = useStripe()
  const elements = useElements()

  // ref used to interact with Stripe CardElement
  const stripeCardElementRef = useRef(null)
  const [formStatus, setFormStatus] = useState(null)

  const handleSubmit = useCallback(
    async values => {
      // check validity of Stripe CardElement
      if (
        stripeCardElementRef.current &&
        !stripeCardElementRef.current.isValid()
      ) {
        return
      }

      // check that everything is ready
      if (!stripe) {
        return
      }

      // set the form into processing mode
      setFormStatus({
        processing: "Please wait while the payment is processed...",
      })

      try {
        // create a payment intent
        const payment = {
          amount: values.payment_amount,
          currencyCode,
        }
        const receipt = {
          email: values.email,
        }
        const client_secret = await PaymentService.createPaymentIntent(
          invoiceToken,
          payment,
          receipt
        )

        // submit the payment to Stripe
        // reference: https://stripe.com/docs/js/elements_object/get_element
        const cardElement = elements.getElement("card")
        const billing_details = {
          name: values.customer_name,
          email: values.email,
          phone: values.customer_phone,
        }
        // reference: https://stripe.com/docs/js/payment_intents/payment_method
        const paymentResult = await stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: cardElement,
            billing_details,
          },
        })
        if (paymentResult.error) {
          throw new Error(paymentResult.error.message)
        }

        // no errors thrown, looks like Stripe received it
        // Stripe triggers a webhook when payment processing is complete
        setFormStatus({
          success: "Payment received!",
        })

        if (onPaymentReceived) {
          onPaymentReceived(values)
        }

        // TODO: redirect to /payments/thank-you/
      } catch (error) {
        setFormStatus({
          error: error.message,
        })
      }
    },
    [
      invoiceToken,
      currencyCode,
      stripe,
      elements,
      stripeCardElementRef,
      setFormStatus,
      onPaymentReceived,
    ]
  )
  return (
    <Form
      name="payment-form"
      generateDataLayer={generateDataLayer || true}
      onSubmit={handleSubmit}
    >
      <PaymentForm
        paymentTerms={paymentTerms}
        currencyCode={currencyCode}
        defaultAmount={defaultAmount}
        minimumAmount={minimumAmount}
        maximumAmount={maximumAmount}
        formStatus={formStatus}
        ref={stripeCardElementRef}
      />
      {formStatus && formStatus.processing && <SpinnerOverlay />}
    </Form>
  )
}
PaymentFormWrapper.propTypes = {
  invoiceToken: PropTypes.string.isRequired,
  paymentTerms: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  currencyCode: PropTypes.string.isRequired,
  defaultAmount: PropTypes.number,
  minimumAmount: PropTypes.number,
  maximumAmount: PropTypes.number,
  generateDataLayer: PropTypes.func,
  onPaymentReceived: PropTypes.func,
}
PaymentFormWrapper.defaultProps = {
  minimumAmount: 10,
}
export default PaymentFormWrapper
